import React, {Suspense, lazy} from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import { RootState } from '../../setup'
import {FallbackView} from '../../_metronic/partials'
import { UserModel } from '../modules/auth/models/UserModel'


export function PrivateRoutes() {

    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    const DashboardPage = lazy(() => import('../modules/dashboard/DashboardPage'))
    const UserPage = lazy(() => import('../modules/cruds/users/UserPage'))
    const UserFormPage = lazy(() => import('../modules/cruds/users/UserFormPage'))
    const CompanyPage = lazy(() => import('../modules/cruds/companies/CompanyPage'))
    const CompanyFormPage = lazy(() => import('../modules/cruds/companies/CompanyFormPage'))


    return (
        <Suspense fallback={<FallbackView />}>

            <Switch>

                <Redirect from='/auth' to='/dashboard' />
                <Redirect exact from='/' to='/dashboard' />

                <Route path='/dashboard' component={DashboardPage} />

                {user.access_level === 'ADMINISTRATOR' &&
                    <>
                        <Route exact path='/cruds/users' component={UserPage} />
                        <Route path='/cruds/users/form/:id?' component={UserFormPage} />

                        <Route exact path='/cruds/companies' component={CompanyPage} />
                        <Route path='/cruds/companies/form/:id?' component={CompanyFormPage} />
                    </>
                }

                <Redirect to='/dashboard' />

        </Switch>

        <div className="modal fade show" tabIndex={-1} aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title">Modal title</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <p>Modal body text goes here.</p>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
                </div>
                </div>
            </div>
        </div>

    </Suspense>
  )
}
