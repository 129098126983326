import { IResponseData, IResponseObject, IMessage } from './models'


export enum ActionsEnum {

	Init = 'Init',

	// FILTRO
	ToggleFilter = 'ToggleFilter',
	Filter = 'Filter',
	ClearFilter = 'ClearFilter',

	// LOADING
	ShowLoading = 'ShowLoading',
	HideLoading = 'HideLoading',

    RequestDomain = 'RequestDomain',
    LoadDomain = 'LoadDomain',
	// DADOS
	RequestData = 'RequestData',
    Request = 'Request',
    ClearData = 'ClearData',
	LoadData = 'LoadData',

	// OBJETO
	RequestObject = 'RequestObject',
    ClearObject = 'ClearObject',
    LoadObject = 'LoadObject',
    RequestObjectModal = 'RequestObjectModal',
    LoadObjectModal = 'LoadObjectModal',

	// FORMULÁRIO
	SendData = 'SendData',

	// EXCLUIR
	Delete = 'Delete',

	// UPLOAD FOTO
	RequestUpload = 'RequestUpload',
	Upload = 'Upload',
    Download = 'Download',


	// REDIRECT
	Redirect = 'Redirect',

	// MENSAGEM
	ShowMessage = 'ShowMessage',
	HideMessage = 'HideMessage',

	// MODAL
	ShowModal = 'ShowModal',
	HideModal = 'HideModal',

	UpdateData = 'UpdateData',

	RequestError = 'RequestError',
	Error = 'Error',
};

export const Actions = {

	init: (page: string) => ({ type: ActionsEnum.Init, payload: { page } }),
	toggleFilter: () => ({ type: ActionsEnum.ToggleFilter }),
	filter: (params: Map<string, any>) => ({ type: ActionsEnum.Filter, payload: params }),
	clearFilter: () => ({ type: ActionsEnum.ClearFilter }),
    requestDomain: (url: string, filters?: any, httpMethod: string = 'get') => ({ type: ActionsEnum.RequestDomain, payload: { url, filters, httpMethod } }),
    loadDomain: (domains: any) => ({ type: ActionsEnum.LoadDomain, payload: { ...domains } }),
	requestData: (url: string, filters?: Map<string, any>, httpMethod: string = 'get') => ({ type: ActionsEnum.RequestData, payload: { url, filters, httpMethod } }),
    request: (url: string, filters?: Map<string, any>, httpMethod: string = 'get') => ({ type: ActionsEnum.Request, payload: { url, filters, httpMethod } }),
	loadData: (response: IResponseData) => ({ type: ActionsEnum.LoadData, payload: { ...response, loading: false } }),
	requestObject: (url:string, id?: number, params?: Map<string, any>, httpMethod: string = 'get') => ({ type: ActionsEnum.RequestObject, payload: { url, id, params, httpMethod } }),
    clearObject: () => ({ type: ActionsEnum.ClearObject }),
	loadObject: (response: IResponseObject) => ({ type: ActionsEnum.LoadObject, payload: { ...response, loading: false } }),
	requestObjectModal: (url:string, id: number, params?: Map<string, any>, httpMethod: string = 'get') => ({ type: ActionsEnum.RequestObjectModal, payload: { url, id, params, httpMethod } }),
	loadObjectModal: (response: IResponseObject) => ({ type: ActionsEnum.LoadObject, payload: { modal: {...response, show: true}, loading: false } }),
	sendData: (url:string, data: any, onSuccess?: Function, messageDetail?: string) => ({ type: ActionsEnum.SendData, payload: { url, data, onSuccess, messageDetail } }),
    download: (url:string, data: any, fileName: string, onSuccess?: Function) => ({ type: ActionsEnum.Download, payload: { url, data, fileName, onSuccess} }),
	delete: (url:string, id:number, onSuccess?: Function, messageDetail?: string) => ({ type: ActionsEnum.Delete, payload: { url, data:{id}, onSuccess, messageDetail } }),
	redirect: (redirect: boolean) => ({ type: ActionsEnum.Redirect, payload: { redirect }}),
	showMessage: (message: IMessage) => ({ type: ActionsEnum.ShowMessage, payload: { message: {...message}, loading: false } }),
	hideMessage: () => ({ type: ActionsEnum.HideMessage, payload: { message: undefined } }),
	upload: (url:string, arquivo:any) => {
		const formData = new FormData();
		formData.set('arquivo', arquivo);
		return { type: ActionsEnum.RequestUpload, payload: {url, formData} }
	},
	updateData: (params: Map<string, any>) => ({ type: ActionsEnum.UpdateData, payload: { ...params }}),

	showModal: (params: Map<string, any>) => ({ type: ActionsEnum.ShowModal, payload: { modal: { show: true}, ...params } }),
	hideModal: () => ({ type: ActionsEnum.HideModal, payload: { modal: { show: false} } }),

	showModalByNome: (modalName:string, modalData: any) => ({ type: ActionsEnum.ShowModal, payload: {[modalName]: true, [`${modalName}Data`]: modalName } }),
	hideModalByNome: (modalName:string) => ({ type: ActionsEnum.HideModal, payload: {[modalName]: false, [`${modalName}Data`]: null }}),


	showLoading: () => ({ type: ActionsEnum.ShowLoading }),
	hideLoading: () => ({ type: ActionsEnum.HideLoading }),
	requestError: (error:any, onError?:Function) => ({ type: ActionsEnum.RequestError, payload: {error, onError} }),
	error: (message:IMessage) => ({ type: ActionsEnum.Error, payload: {...message, loading: false} }),

};
