import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as page from '../../app/redux/pagina';

export const RootReducer = combineReducers({
  auth: auth.reducer,
  page: page.reducer
})

export type RootState = ReturnType<typeof RootReducer>

export function* RootSaga() {
  yield all([auth.saga(), page.saga()])
}
