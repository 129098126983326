import React from 'react'
import {useLayout} from '../../core/LayoutProvider'
import {ToolbarUser} from './ToolbarUser'
import { ToolbarDefault } from './ToolbarDefault'
import { ToolbarCRUDDefault } from './ToolbarCRUDDefault'

const Toolbar = () => {

    const { config } = useLayout();

    switch (config.toolbar.layout) {

        case 'user':
            return <ToolbarUser />

        case 'crud':
            return <ToolbarCRUDDefault />

        default:
            return <ToolbarDefault />
    }
}

export { Toolbar }
