/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

export function AsideMenuMain() {

    const intl = useIntl()
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
            />

            {user.access_level === 'ADMINISTRATOR' &&
                <>
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administração</span>
                        </div>
                    </div>
                    <AsideMenuItemWithSub
                        to='/administracao/cadastros'
                        title='Cadastros'
                        fontIcon='bi-archive'
                        icon='/media/icons/duotune/general/gen022.svg'
                    >
                        <AsideMenuItem to='/cruds/companies' title='Empresas' hasBullet={true} />
                        <AsideMenuItem to='/cruds/users' title='Usuários' hasBullet={true} />
                    </AsideMenuItemWithSub>
                </>
            }
        </>
    )
}
