import * as Page from './actions';
import { IPageState, IActionWithPayload } from './models';


const initialPageState: IPageState = {
    page: undefined,
    showFilter: false,
    data: [],
    domains: [],
    pagination: undefined,
    currentData: [],
    object: {},
    filters: new Map<string, any>(),
    loading: false,
    message: undefined,
    modal:  {show: false, title: undefined, content: undefined },
    redirect: false
}



export const reducer = (state: IPageState = initialPageState, action: IActionWithPayload<IPageState>) => {

    switch (action.type) {

		// INIT
		case Page.ActionsEnum.Init:
			return { ...initialPageState, ...action.payload };

		// FILTRO
		case Page.ActionsEnum.ToggleFilter:
			return { ...state, showFilter: !state.showFilter };
		case Page.ActionsEnum.ClearFilter:
			return { ...state, showFilter: initialPageState.showFilter, currentData: state.data, filters: initialPageState.filters };

		// LOADING
		case Page.ActionsEnum.ShowLoading:
			return { ...state, loading: true };
		case Page.ActionsEnum.HideLoading:
			return { ...state, loading: false };

		// DADOS
		case Page.ActionsEnum.ClearData:
			return { ...state, data: initialPageState, currentData: initialPageState.currentData };

		case Page.ActionsEnum.LoadData:
			return { ...state, ...action.payload, object: {} };

        case Page.ActionsEnum.LoadDomain:
			return { ...state, domains: {...state.domains, ...action.payload?.domains }  };

		// OBJETO
        case Page.ActionsEnum.RequestObject:
			return { ...state, domains: [], object: {} };

        case Page.ActionsEnum.LoadObject:
            return { ...state, ...action.payload };

        case Page.ActionsEnum.ClearObject:
			return { ...state, object: {} };

		// UPLOAD
		case Page.ActionsEnum.Upload:
			return { ...state, ...action.payload };

		// REDIRECT
		case Page.ActionsEnum.Redirect:
			return { ...state, ...action.payload };

		// MENSAGEM
		case Page.ActionsEnum.ShowMessage:
			return { ...state, ...action.payload };
		case Page.ActionsEnum.HideMessage:
			return { ...state, ...action.payload };

		// MODAL
		case Page.ActionsEnum.ShowModal:
			return { ...state, ...action.payload, showModal: true };
		case Page.ActionsEnum.HideModal:
			return { ...state, ...action.payload, showModal: false };

		case Page.ActionsEnum.UpdateData:
			return { ...state, ...action.payload };

		case Page.ActionsEnum.Error:
			return { ...state, ...action.payload };

		default:
			return state;

	}
};
