import { all, takeEvery, call, fork, put } from 'redux-saga/effects';
import * as Page from './actions';
import {IRequest, IRequestData, ISendData, IActionWithPayload, IResponse, IResponseData, IResponseObject, IError, IMessage, IPagination } from '.';
import axios, {AxiosResponse}  from 'axios';
import FileSaver from 'file-saver';


const API_URL = process.env.REACT_APP_API_URL || 'api'


const SagaUtils = {
    httpMethod: (request:ISendData) => request.data.id ? 'put' : 'post',
    createOrEdit: (request:IRequest) => request.id ? `${API_URL}${request.url}/${request.id}/edit` : `${API_URL}${request.url}/create`,
    urlId: (request:IRequest) => request.id ? `${API_URL}${request.url}/${request.id}` : `${API_URL}${request.url}`,
    getDataId: (data: any) => data.id ? data.id : data.get ? data.get('id') : null,
    urlDataId: (request:ISendData) => SagaUtils.getDataId(request.data) ? `${API_URL}${request.url}/${SagaUtils.getDataId(request.data)}` : `${API_URL}${request.url}`,
    url: (request:IRequest) => `${API_URL}${request.url}`,
    urlDelete: (request:ISendData) => `${API_URL}${request.url}/${request.data.id}`,
    params: (request:IRequestData) => request.httpMethod === 'get' ? {params:{...request.filters}} : {data:{...request.filters}},
    get: (request:IRequest) => axios.request<IResponse<undefined>>({url: SagaUtils.urlId(request), method: request.httpMethod, ...SagaUtils.params(request)}),
    request: (url:string, request:IRequest) => axios.request<IResponse<undefined>>({url, method: request.httpMethod, ...SagaUtils.params(request)}),
    send: (request:ISendData) => axios.request({url: SagaUtils.urlDataId(request), method: SagaUtils.httpMethod(request), data: request.data }),
    pagination: (response:IResponse<IResponseData>) => {
        const {data, ...pagination} = response.data || {};
        return pagination as IPagination;
    },
    data: (response:IResponse<IResponseData>) => response.data?.data || [],
    object: (response:IResponse<IResponseObject>) => response.data || {},
    readAsText: (blob: Blob) => {
        return new Promise(resolve => {
          const reader  = new FileReader();
          reader.onload = () => resolve(reader.result)
          reader.readAsText(blob)
        })
      }
};

const SagaPagina = {

    requestDomain: function* () {
		yield takeEvery([Page.ActionsEnum.RequestDomain],
            function*(action: IActionWithPayload<IRequest>) {

            const requestData: IRequest = yield action.payload;

			try{

                const response: IResponse<IResponseData> = yield call(SagaUtils.get, requestData);
                yield put(Page.Actions.loadDomain(response.data));

			} catch(error) {

				yield put(Page.Actions.requestError(error));
			}

		});
	},

	requestData: function* () {
		yield takeEvery([Page.ActionsEnum.Request, Page.ActionsEnum.RequestData, Page.ActionsEnum.RequestObject, Page.ActionsEnum.RequestObjectModal],
            function*(action: IActionWithPayload<IRequest>) {

            const requestData: IRequest = yield action.payload;

			try{

				yield put(Page.Actions.showLoading());

                if(action.type === Page.ActionsEnum.RequestData){
                    const URL:string = yield SagaUtils.urlId(requestData);
                    const response: IResponse<IResponseData> = yield call(SagaUtils.request, URL, requestData);

                    const pagination: IPagination = yield SagaUtils.pagination(response);
                    const data: any = yield SagaUtils.data(response);
                    const responseData: IResponseData = yield { pagination, data };
                    yield put(Page.Actions.loadData(responseData));

                }
                if(action.type === Page.ActionsEnum.Request){
                    const URL:string = yield SagaUtils.urlId(requestData);
                    const response: IResponse<IResponseObject> = yield call(SagaUtils.request, URL, requestData);
                    const data = SagaUtils.object(response);
                    const responseObject: IResponseObject = { object: data };
                    yield put(Page.Actions.loadObject(responseObject));
                }

                if(action.type === Page.ActionsEnum.RequestObject || action.type === Page.ActionsEnum.RequestObjectModal) {

                    const URL:string = SagaUtils.createOrEdit(requestData);
                    const response: IResponse<IResponseObject> = yield call(SagaUtils.request, URL, requestData);
                    const data = SagaUtils.object(response);
                    const responseObject: IResponseObject = { ...data, modal: false };

                    if(action.type === Page.ActionsEnum.RequestObject || action.type === Page.ActionsEnum.Request){
                        yield put(Page.Actions.loadObject(responseObject));
                    }else{
                        yield put(Page.Actions.loadObjectModal(responseObject));
                    }
                }

                yield put(Page.Actions.hideLoading());

			} catch(error) {

				yield put(Page.Actions.requestError(error));
			}

		});
	},

	sendData: function* () {
		yield takeEvery(Page.ActionsEnum.SendData, function* (action: IActionWithPayload<ISendData>) {
			const sendData :ISendData = yield action.payload;

			try {

				yield put(Page.Actions.showLoading());

                yield call(SagaUtils.send, sendData);
                const message: IMessage = yield {type: 'success', title: sendData.messageTitle || 'Operação realizada com sucesso.',  detail: sendData.messageDetail };

                if(sendData.onSuccess)
                    yield sendData.onSuccess();

				yield put(Page.Actions.showMessage(message));

                yield put(Page.Actions.hideLoading());
			}catch(error){

                yield put(Page.Actions.hideLoading());
				yield put(Page.Actions.requestError(error, sendData.onError));
			}


		});
	},

	delete: function* () {
		yield takeEvery(Page.ActionsEnum.Delete, function* (action: IActionWithPayload<ISendData>) {
			const sendData :ISendData = yield action.payload;

            try {

				yield put(Page.Actions.showLoading());
                const url:string  = yield SagaUtils.urlDelete(sendData);
				yield call(axios.delete, url);
                const message: IMessage = yield {type: 'success', title: sendData.messageTitle || 'Operação realizada com sucesso.',  detail: sendData.messageDetail };

                if(sendData.onSuccess){
                    yield sendData.onSuccess();
                }

                yield put(Page.Actions.showMessage(message));
                yield put(Page.Actions.hideLoading());

			}catch(error){
                console.log(error);
				yield put(Page.Actions.requestError(error, sendData.onError));
			}

		});
	},

    download: function* () {
        yield takeEvery(Page.ActionsEnum.Download, function* (action: IActionWithPayload<ISendData>) {
			const sendData :ISendData = yield action.payload;

			try {

				yield put(Page.Actions.showLoading());

                const response: AxiosResponse = yield call(axios.request, { method: 'post', responseType: 'arraybuffer', url: API_URL + sendData.url, data: sendData.data });

				const file: Blob = yield new Blob([response.data], { type: "application/pdf;charset=utf-8" });

				yield FileSaver.saveAs(file, sendData.fileName);

                if(sendData.onSuccess)
                    yield sendData.onSuccess();

                yield put(Page.Actions.hideLoading());

			}catch(error: any){
                const blob = new Blob([error.response.data], {type: "application/json"});
				const retorno: string = yield call(SagaUtils.readAsText, blob);
				const data: any = yield JSON.parse(retorno);
				yield put(Page.Actions.requestError({response : { ...error.response, data}}, sendData.onError));
                yield put(Page.Actions.hideLoading());
			}


		});


	},


	requestError: function* () {
		yield takeEvery(Page.ActionsEnum.RequestError, function* (action: IActionWithPayload<IError>) {
			const { error, callback } = yield action.payload;
            console.log('ERROR > ',error)
			let messageDetails:string = yield 'Ocorreu um erro. Tente novamente mais tarde.';
			let message:IMessage;

			if(!error.response)
                message = yield {type: 'danger', title: 'Ocorreu um erro.',  detail: messageDetails, callback };
			else if (error.response.status === 400){
				if(error.response.data.fieldsValidation)
                    message = yield {type: 'warning', title: 'Preenchimento incorreto dos campos.',  validations: error.response.data.errors, callback };
				else if(error.response.data.validacao)
                    message = yield {type: 'warning', title: 'A operação não pode ser realizada.',  detail: error.response.data.message, callback };
				else
                    message = yield {type: 'warning', title: 'Atenção',  detail: error.response.data.message, callback };

			}else if (error.response.status === 401)
                message = yield {type: 'warning', title: 'Login expirado.',  detail: 'Realize o login novamente, para continuar utilizando o sistema.', callback};
			else {
                if(error.response.data){
                    message = yield {type: 'danger', title: 'Ocorreu um erro.',  detail: error.response.data.message, callback};

                }else{
                    message = yield {type: 'danger', title: 'Ocorreu um erro.',  detail: messageDetails, callback};
                }

			}

			yield put(Page.Actions.showMessage(message));

		});
	},
}

export function* saga() {
	yield all([
		fork(SagaPagina.requestDomain),
        fork(SagaPagina.requestData),
        fork(SagaPagina.sendData),
        fork(SagaPagina.delete),
        fork(SagaPagina.download),
        fork(SagaPagina.requestError),
	]);
}
