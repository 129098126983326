import clsx from 'clsx'
import React, {FC} from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { PATH_AVATAR_BLANK, toStorageUrl } from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'


const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';


const Topbar: FC = () => {

    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel


    return (
        <div className='d-flex align-items-stretch flex-shrink-0'>
            {/* begin::User */}
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)} id='kt_header_user_menu_toggle'>
                {/* begin::Toggle */}
                <div className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)} data-kt-menu-trigger='click' data-kt-menu-attach='parent' data-kt-menu-placement='bottom-end' data-kt-menu-flip='bottom' >
                    <img src={user?.company.logo ? toStorageUrl(user?.company.logo) : PATH_AVATAR_BLANK} alt='metronic' />
                </div>

                <HeaderUserMenu />
                {/* end::Toggle */}
            </div>
            {/* end::User */}
        </div>
    )
}

export { Topbar }
