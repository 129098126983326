/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import { resetPassword } from '../redux/AuthCRUD'
import { Link } from 'react-router-dom'


interface Param { resetPasswordToken: string };

const initialValues = {
    password: '',
    password_confirmation: '',

};


const changePasswordSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Mínimo de 8 caracteres').max(12, 'Máximo de 12 caracteres').required('Informe a Senha'),
    password_confirmation: Yup.string().required('Informe a confirmação da senha').when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], "A Senha e a Confirmação, não são iguais"),
    })
});

export function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { resetPasswordToken } = useParams<Param>();

    const formik = useFormik({
        initialValues,
        validationSchema: changePasswordSchema,
        onSubmit: ({ password, password_confirmation }, {setStatus, setSubmitting}) => {

            setLoading(true);

            resetPassword(resetPasswordToken, password, password_confirmation)
                .then(({data: {accessToken}}) => {
                    setLoading(false);
                    dispatch(auth.actions.login(accessToken));
                })
                .catch((error) => {
                    setLoading(false)
                    setSubmitting(false)
                    setStatus(error.response.data.message)
                });
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Informe sua nova Senha</h1>
        {/* end::Title */}

      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Senha'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
                <div className="invalid-feedback">
                    <span role='alert'>{formik.errors.password}</span>
                </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirmação da Senha</label>
        <input
          type='password'
          placeholder='Confirmação da Senha'
          autoComplete='off'
          {...formik.getFieldProps('password_confirmation')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
            }
          )}
        />
        {formik.touched.password_confirmation && formik.errors.password_confirmation && (
            <div className="invalid-feedback">
                <span role='alert'>{formik.errors.password_confirmation}</span>
            </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid }
        >
          {!loading && <span className='indicator-label'>Enviar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde, por favor...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancelar
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
