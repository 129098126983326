import axios from 'axios'
import { UserModel } from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const USER_INFO_URL = `${API_URL}/auth/user-info`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function resetPassword(token: string, password: string, password_confirmation: string) {
    return axios.post(RESET_PASSWORD_URL, { token, password, password_confirmation });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(USER_INFO_URL)
}
