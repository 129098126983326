/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {DefaultTitleCRUD} from '../header/page-title/DefaultTitleCRUD'
import {usePageData} from '../../core/PageData'

const ToolbarCRUDDefault : FC = () => {
    const { pageActions } = usePageData()
    const {classes} = useLayout()

    return (
        <div className='toolbar' id='kt_toolbar'>
            {/* begin::Container */}
            <div id='kt_toolbar_container' className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')} >
                <DefaultTitleCRUD />
                <div className='d-flex align-items-center py-1'>
                    {pageActions}
                </div>
            </div>
            {/* end::Container */}
        </div>
    )
}

export { ToolbarCRUDDefault }
